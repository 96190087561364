<template>
<div class='b-add-worker__wrapper container'>
    <div class='b-base-title__wrapper'>
        <h2 class='b-appointment-left-bar__title'>
            {{ !isBranchAdvisorAdd ? $t('ADVISOR.FORM.EDIT.TITLE') : $t('ADVISOR.FORM.TITLE') }}
        </h2>
    </div>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <form
        v-else
        class='b-add-worker__wrapper-form'>
        <h2 class='h-font-22'>
            {{ $t('SELECT.ADDRESS.BRANCH.CONTACT.DETAILS') }}
        </h2>
        <div class='b-add-worker__wrapper__line'>
            <FwFormInput
                class='h-flex-1-0 h-mr-30'
                :fieldHasError='fieldHasError("first_name")'
                labelType='label-medium'
                :title='`${$t(`WORKER.FORM.FIRST.NAME.LABEL`)}*`'>
                <FwInput
                    class='qa-advisor-name'
                    :value='first_name'
                    type='text'
                    name='text'
                    :placeholder='$t(`WORKER.FORM.FIRST.NAME.PLACEHOLDER`)'
                    inputStyle='white-bigger'
                    :disabled='disabledForm'
                    :error='getFieldErrorText("first_name")'
                    @update:value='value => first_name = value'
                    @input='clearServerErrorsBase("first_name")'
                    @blur='addCurrentInputToValidateArray("first_name")'>
                </FwInput>
            </FwFormInput>

            <FwFormInput
                class='h-flex-1-0 h-mr-30'
                :fieldHasError='fieldHasError("last_name")'
                labelType='label-medium'
                :title='`${$t(`WORKER.FORM.LAST.NAME.LABEL`)}*`'>
                <FwInput
                    class='qa-advisor-last-name'
                    :value='last_name'
                    type='text'
                    name='text'
                    :placeholder='$t(`WORKER.FORM.LAST.NAME.PLACEHOLDER`)'
                    inputStyle='white-bigger'
                    :disabled='disabledForm'
                    :error='getFieldErrorText("last_name")'
                    @update:value='value => last_name = value'
                    @input='clearServerErrorsBase("last_name")'
                    @blur='addCurrentInputToValidateArray("last_name")'>
                </FwInput>
            </FwFormInput>

            <FwFormInput
                class='h-flex-1-0'
                labelType='label-medium'
                :title='`${$t(`LABEL.EXTERNAL.ID`)}`'>
                <FwInput
                    :value='remote_id'
                    type='text'
                    name='text'
                    :placeholder='$t(`LABEL.EXTERNAL.ID`)'
                    inputStyle='white-bigger'
                    @update:value='value => remote_id = value'>
                </FwInput>
            </FwFormInput>
        </div>

        <div class='b-add-worker__wrapper__line'>
            <FwFormInput
                class='h-flex-1-0 h-mr-30'
                labelType='label-medium'
                :title='`${$t(`WORKER.FORM.PHONE.LABEL`)}`'>
                <FwInput
                    class='qa-advisor-phone'
                    :value='phone'
                    type='number'
                    name='text'
                    :placeholder='$t(`WORKER.FORM.PHONE.PLACEHOLDER`)'
                    inputStyle='white-bigger'
                    :disabled='disabledForm'
                    :propsKeyDownMethod='propsKeyDownMethodOnlyNumbers'
                    @update:value='value => phone = value'>
                </FwInput>
            </FwFormInput>

            <FwFormInput
                class='h-flex-1-0 h-mr-30'
                :fieldHasError='fieldHasError("email")'
                labelType='label-medium'
                :title='`${$t(`WORKER.FORM.EMAIL.LABEL`)}*`'>
                <FwInput
                    class='qa-advisor-email'
                    :value='email'
                    type='text'
                    name='text'
                    :placeholder='$t(`WORKER.FORM.EMAIL.PLACEHOLDER`)'
                    inputStyle='white-bigger'
                    :disabled='disabledForm'
                    :error='getFieldErrorText("email")'
                    @update:value='value => email = value'
                    @input='clearServerErrorsBase("email")'
                    @blur='addCurrentInputToValidateArray("email")'>
                </FwInput>
            </FwFormInput>

            <FwFormInput
                class='h-flex-1-0'
                :fieldHasError='fieldHasError("professional_title")'
                labelType='label-medium'
                :title='$t(`LABEL`)'>
                <FwInput
                    class='qa-advisor-label'
                    :value='professional_title'
                    type='text'
                    name='text'
                    :placeholder='$t(`LABEL`)'
                    inputStyle='white-bigger'
                    @update:value='value => professional_title = value'>
                </FwInput>
            </FwFormInput>
        </div>
        <h2 class='h-font-22'>
            {{ $t('SELECT.BOOKING.PARAMETERS.TITLE') }}
        </h2>
        <div class='b-add-worker__wrapper__line'>
            <FwFormInput
                v-if='skills'
                labelType='label-medium'
                class='h-flex-1-0 h-mr-30'
                :title='`${$t(`WORKER.FORM.SKILLS.LABEL`)}`'>
                <div class='fw-select-base'>
                    <FwSelect
                        v-model='skillChoosed'
                        class='fw-select-with-icon--big qa-advisor-select-skill'
                        :propsPlaceholder='$t(`SKILLS.YOU.NEED.SELECT`)'
                        type='white'
                        :searchable='false'
                        :label='labelKey'
                        openDirection='bottom'
                        :multiple='true'
                        :clearable='false'
                        :withCircles='true'
                        :canNotDelete='false'
                        :options='skills'>
                    </FwSelect>
                </div>
            </FwFormInput>
            <FwFormInput
                v-if='categoryLocationSkills'
                labelType='label-medium'
                class='h-flex-1-0'
                :title='`${$t(`LABEL.APPOINTMENT.METHODS`)}*`'>
                <div class='fw-select-base'>
                    <FwSelect
                        v-model='locationSkillChoosed'
                        class='fw-select-with-icon--big qa-advisor-select-method'
                        :propsPlaceholder='$t(`WORKER.FORM.SKILLS.PLACEHOLDER`)'
                        type='white'
                        :searchable='false'
                        :label='labelKey'
                        openDirection='bottom'
                        :multiple='true'
                        :clearable='false'
                        title
                        :withCircles='true'
                        :canNotDelete='false'
                        :options='categoryLocationSkillsFiltered'>
                    </FwSelect>
                </div>
            </FwFormInput>
        </div>
        <template v-if='!isMethodWithOutGeoZone'>
            <div v-if='geograficIds.length !== 0' class='h-pos-rel'>
                <FwFormInput
                    labelType='label-medium'
                    :title='`${$t(`WORKER.FORM.ZONE.LABEL`)}*`'>
                    <div class='h-flex h-flex-wrap h-pb-30' style='margin: 0 -15px;'>
                        <div v-for='item in geograficIds'
                             :key='item'
                             style='width: 435px; height: 60px;'
                             class='b-team-list h-mh-15'>
                            <template v-if='item'>
                                <div class='b-team-list__body'>
                                    <div class='h-fw-500'>
                                        {{ item.post_code }} - {{ item.name }}
                                    </div>
                                </div>
                                <div class='b-team-list__delete'>
                                    <FwIcon
                                        class='h-flex-center h-pointer'
                                        icon='bucket-edit'
                                        size='20'
                                        color='rgba(33,63,107,.3)'
                                        @click.native='deleteItem(item)'>
                                    </FwIcon>
                                </div>
                            </template>
                        </div>
                    </div>
                </FwFormInput>
                <div class='h-pos-abs h-fw-500 h-font-14 h-pointer'
                     style='top: 0; left: 150px;color: #27dbbd;'
                     @click='$emit(`openGeograficPopup`)'>
                    {{ $t('LABEL.GEO.ZONE') }}
                </div>
            </div>
            <div v-else
                 class='b-add-worker__wrapper__line b-add-worker__input__inner'>
                <FwFormInput
                    labelType='label-medium'
                    class='h-flex-1-0 h-mr-30 qa-advisor-open-gz-button'
                    :title='`${$t(`WORKER.FORM.ZONE.LABEL`)}*`'>
                    <div class='fw-select-base'>
                        <div class='b-team-list__body h-pointer'
                             @click='$emit(`openGeograficPopup`)'>
                            <div class='h-fw-500' style='color: rgba(33,63,107,.5);'>
                                {{ $t('WORKER.FORM.GEOGRAFIC.SELECT.PLACEHOLDER') }}
                            </div>
                            <FwIcon
                                class='h-flex-center h-pointer'
                                icon='edit-settings'
                                size='20'
                                color='#203f6a'>
                            </FwIcon>
                        </div>
                    </div>
                </FwFormInput>
            </div>
        </template>
        <h2 class='h-font-22'>
            {{ $t('Manager') }}
        </h2>
        <div class='b-add-worker__wrapper__line'>
            <div class='b-add-worker__input-wrapper b-add-worker__input__inner'>
                <FwFormInput
                    class='h-flex-1-0 h-mr-30'
                    :fieldHasError='fieldHasError("admin")'
                    labelType='label-medium'
                    :title='`${$t(`Manager`)}`'>
                    <FwLabelCard
                        class='h-flex-1-0 b-label-card'>
                        <div class='h-flex h-flex-center h-flex-space-between h-height-100p'>
                            <div class='b-add-edit__form__option h-flex h-flex-center'>
                                <span class='b-add-worker__is_admin_active'>
                                    {{ isActiveManager ? $t('WORKER.FORM.ADMIN.YES') : $t('WORKER.FORM.ADMIN.NO') }}
                                </span>
                            </div>
                            <FwSwitcher
                                :isActive='isActiveManager'
                                :isRequestSending='isActiveAdminRequestSending'
                                @toggleSwitch='toggleAdmin'>
                            </FwSwitcher>
                        </div>
                    </FwLabelCard>
                </FwFormInput>
            </div>
            <FwSpinLoader
                v-if='isLoadingRoles'
                :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
                class='h-flex-center loader'
                :isActive='isLoadingRoles'
                className='h-p-20'>
            </FwSpinLoader>
            <div v-else-if='isActiveManager'
                 class='b-add-worker__input-wrapper b-add-worker__input__inner'>
                <FwFormInput
                    labelType='label-medium'
                    class='h-flex-1-0 h-mr-30'
                    :title='`${$t(`WORKERS.FORM.INPUT.ROLE.LABEL`)}*`'>
                    <div class='fw-select-base'>
                        <FwSelect
                            v-model='roleValue'
                            class='qa-advisor-select-role'
                            type='white'
                            :propsPlaceholder='`${$t(`WORKERS.FORM.INPUT.ROLE.CHOOSE`)}`'
                            label='name'
                            :canNotDelete='true'
                            :searchable='false'
                            :multiply='false'
                            :options='roles'>
                        </FwSelect>
                    </div>
                </FwFormInput>
            </div>
        </div>

        <template v-if='servicePointSupport'>
            <h2 class='h-font-22'>
                {{ $t('SELECT.SERVICES_POINT.ONE.TITLE') }}
            </h2>
            <div class='h-flex'>
                <FwFormInput
                    class='h-flex-1-0'
                    :fieldHasError='fieldHasError("admin")'
                    labelType='label-medium'
                    :title='`${$t(`SELECT.SERVICES_POINT.ONE.TITLE`)}`'>
                    <FwLabelCard
                        class='h-flex-1-0 b-label-card'>
                        <div class='h-flex h-flex-center h-flex-space-between h-height-100p'>
                            <div class='b-add-edit__form__option h-flex h-flex-center'>
                                <span class='b-add-worker__is_admin_active'>
                                    {{ isServicePoint ? $t('WORKER.FORM.ADMIN.YES') : $t('WORKER.FORM.ADMIN.NO') }}
                                </span>
                            </div>
                            <FwSwitcher
                                :isActive='isServicePoint'
                                @toggleSwitch='toggleService'>
                            </FwSwitcher>
                        </div>
                    </FwLabelCard>
                </FwFormInput>
            </div>
        </template>

        <div v-if='(
            (isOrganizationWithHubs && !hubValue && !branchValue) ||
            (hubValue && isOrganizationWithBranches) ||
            (hubValue && isOrganizationWithHubs) ||
            isOrganizationWithAll ||
            isOrganizationWithSPAndHubs
        )'>
            <h2 class='h-font-22'>
                {{ $t('WORKER.FORM.HUB.MEMBERSHIP') }}
            </h2>
            <div class='b-add-worker__wrapper__line'>
                <FwFormInput
                    labelType='label-medium'
                    class='b-add-worker__input__280'
                    :title='`${$t(`WORKER.FORM.HUB.LABEL`)}${isOrganizationWithAll ? `**` : `*`}`'>
                    <div class='fw-select-base fw-select--uppercase'>
                        <FwSelect
                            v-if='(
                                (isOrganizationWithHubs && !hubValue && !branchValue) ||
                                (hubValue && isOrganizationWithBranches) ||
                                (hubValue && isOrganizationWithHubs) ||
                                isOrganizationWithAll ||
                                isOrganizationWithSPAndHubs
                            )'
                            v-model='hubValue'
                            class='qa-advisor-select-hub'
                            type='white'
                            :propsPlaceholder='`${$t(`WORKER.FORM.HUB.PLACEHOLDER`)}`'
                            label='name'
                            :disabled='(hubValueFromServer && !isBranchAdvisorAdd || isBranchHubHide) && (hubValueFromServer && isOrganizationWithAll)'
                            :canNotDelete='isNotOrganizationWithAll'
                            :searchable='true'
                            :multiply='false'
                            :options='sortedHubs'>
                        </FwSelect>
                    </div>
                </FwFormInput>
                <FwFormInput
                    class='b-add-worker__input__280'
                    :fieldHasError='fieldHasError("admin")'
                    labelType='label-medium'
                    :title='`${$t(`WORKER.FORM.HUB.ADMIN.LABEL`)}`'>
                    <FwLabelCard
                        class='h-flex-1-0 b-label-card'>
                        <div class='h-flex h-flex-center h-flex-space-between h-height-100p'>
                            <div class='b-add-edit__form__option h-flex h-flex-center'>
                                <span class='b-add-worker__is_admin_active'>
                                    {{ isActiveHubAdmin ? $t('WORKER.FORM.ADMIN.YES') : $t('WORKER.FORM.ADMIN.NO') }}
                                </span>
                            </div>
                            <FwSwitcher
                                :isActive='isActiveHubAdmin'
                                :isRequestSending='isActiveAdminRequestSending'
                                @toggleSwitch='isActiveHubAdmin = !isActiveHubAdmin'>
                            </FwSwitcher>
                        </div>
                    </FwLabelCard>
                </FwFormInput>
                <FwFormInput
                    v-if='isMatmutAccount'
                    class='b-add-worker__input__280'
                    :fieldHasError='fieldHasError("code_site")'
                    labelType='label-medium'
                    title='Code site'>
                    <FwInput
                        :value='code_site'
                        type='text'
                        name='text'
                        :disabled='disabledForm'
                        inputStyle='white-bigger'
                        placeholder='ex: 123 456'
                        @update:value='value => code_site = value'>
                    </FwInput>
                </FwFormInput>
            </div>
        </div>
        <div v-if='(
            (isOrganizationWithBranches && !hubValue && !branchValue) ||
            (branchValue && isOrganizationWithHubs) ||
            (branchValue && isOrganizationWithBranches) ||
            isOrganizationWithAll
        )'>
            <h2 class='h-font-22'>
                {{ $t('WORKER.FORM.BRANCH.MEMBERSHIP') }}
            </h2>
            <div class='b-add-worker__wrapper__line'>
                <FwFormInput
                    labelType='label-medium'
                    class='b-add-worker__input__280'
                    :title='`${$t(`WORKER.FORM.BRANCH.LABEL`)}${isOrganizationWithAll ? `**` : `*`}`'>
                    <div>
                        <div class='fw-select-base fw-select--uppercase'>
                            <FwSelect
                                v-if='(
                                    (isOrganizationWithBranches && !hubValue && !branchValue) ||
                                    (branchValue && isOrganizationWithHubs) ||
                                    (branchValue && isOrganizationWithBranches) ||
                                    isOrganizationWithAll
                                )'
                                v-model='branchValue'
                                class='qa-advisor-select-branch'
                                type='white'
                                :propsPlaceholder='`${$t(`WORKER.FORM.BRANCH.PLACEHOLDER`)}`'
                                label='name'
                                :disabled='(branchValueFromServer && !isBranchAdvisorAdd || isBranchHubHide) && (branchValueFromServer && isOrganizationWithAll)'
                                :canNotDelete='isNotOrganizationWithAll'
                                :searchable='true'
                                :multiply='false'
                                :options='sortedBranches'>
                            </FwSelect>
                        </div>
                    </div>
                </FwFormInput>
                <FwFormInput
                    class='b-add-worker__input__280'
                    :fieldHasError='fieldHasError("admin")'
                    labelType='label-medium'
                    :title='`${$t(`BRANCH.FORM.LABEL`)}`'>
                    <FwLabelCard
                        class='h-flex-1-0 b-label-card'>
                        <div class='h-flex h-flex-center h-flex-space-between h-height-100p'>
                            <div class='b-add-edit__form__option h-flex h-flex-center'>
                                <span class='b-add-worker__is_admin_active'>
                                    {{ isActiveBranchAdmin ? $t('WORKER.FORM.ADMIN.YES') : $t('WORKER.FORM.ADMIN.NO') }}
                                </span>
                            </div>
                            <FwSwitcher
                                :isActive='isActiveBranchAdmin'
                                :isRequestSending='isActiveAdminRequestSending'
                                @toggleSwitch='isActiveBranchAdmin = !isActiveBranchAdmin'>
                            </FwSwitcher>
                        </div>
                    </FwLabelCard>
                </FwFormInput>
            </div>
        </div>
        <template v-if='isTypeSite'>
            <div v-if='(branches && isNotOrganizationWithHubs) || isOrganizationWithAll'>
                <h2 class='h-font-22'>
                    {{ $t('LABEL.START.POINT') }}
                </h2>
                <div class='h-flex h-flex-justify-start'>
                    <FwFormInput
                        class='h-pb-30 b-add-worker__input__280'
                        :fieldHasError='fieldHasError("admin")'
                        labelType='label-medium'
                        :title='`${$t(`ADVISOR.SAME.BRANCH.ADDRESS`)}*`'>
                        <FwLabelCard
                            :disabled='isInitiaStartPoinDisabled'
                            class='h-flex-1-0 b-label-card'>
                            <div class='h-flex h-flex-center h-flex-space-between h-height-100p'>
                                <div class='b-add-edit__form__option h-flex h-flex-center'>
                                    <span class='b-add-worker__is_admin_active'>
                                        {{ isBranchAddress ? $t('WORKER.FORM.ADMIN.YES') : $t('WORKER.FORM.ADMIN.NO') }}
                                    </span>
                                </div>
                                <FwSwitcher
                                    :isActive='isBranchAddress'
                                    :isRequestSending='isActiveAdminRequestSending'
                                    @toggleSwitch='isBranchAddress = !isBranchAddress'>
                                </FwSwitcher>
                            </div>
                        </FwLabelCard>
                    </FwFormInput>
                    <div v-if='isDeparturePoint && !isBranchAdvisorAdd'
                         class='h-flex h-flex-center h-flex-justify-start b-update-address__checkbox b-add-worker__input__inner h-ml-20'>
                        <div v-if='addressUpdateData'>
                            <div class='h-flex h-flex-space-between h-flex-center'>
                                <div class='h-flex h-flex-center'>
                                    <FwIcon
                                        icon='reccurance-icon'
                                        size='20'
                                        color='#BEC7D4'>
                                    </FwIcon>
                                    <span class='h-ml-10 h-fw-500 h-font-14'>
                                        {{ $t('UPDATE.TITLE.POPUP.SCHEDULED.UPDATE') }}
                                    </span>
                                </div>
                            </div>
                            <p class='h-mt-5 h-font-12'>
                                {{ $t('UPDATE.ADDRESS.DESCRIPTION') }} "{{ addressUpdateData.new_data.departure_point }}" {{ $t('UPDATE.ADDRESS.ON') }} {{ updateDate }}
                            </p>
                        </div>
                        <FwCheckbox
                            v-else
                            v-model='isUpdateAddress'
                            :value='isUpdateAddress'
                            type='white-with-text-transparent'
                            :text='$t(`UPDATE.ADDRESS.LABEL`)'
                            :itemWidth='`auto`'
                            name='checkbox'>
                        </FwCheckbox>
                    </div>
                </div>
                <AutocompleteSelector
                    v-if='!isBranchAddress'
                    :startAddress='address'
                    :countryData='country'
                    :isDisabled='isInitiaStartPoinDisabled'
                    isFullSpace
                    isRequired
                    @changePostCode='value => post_code = value'
                    @input='input'
                    @update='value => country = value'>
                </AutocompleteSelector>
            </div>
            <div v-if='hubs && isNotOrganizationWithAll && isNotOrganizationWithBranches'>
                <h2 class='h-font-22'>
                    {{ $t('LABEL.START.POINT') }}
                </h2>
                <AutocompleteSelector
                    :startAddress='address'
                    :countryData='country'
                    :isDisabled='isInitiaStartPoinDisabled'
                    isFullSpace
                    isRequired
                    @changePostCode='value => post_code = value'
                    @input='input'
                    @update='value => country = value'>
                </AutocompleteSelector>
            </div>
        </template>
        <div v-if='isOrganizationWithAll'>
            <div class='h-fw-500 h-font-14'>
                *{{ $t('WORKER.FORM.REQUIRED.FIELDS') }}
            </div>
            <div class='h-fw-500 h-font-14 h-mt-10 h-mb-30'>
                **{{ $t('WORKER.FORM.REQUIRED.ONE.OF.TWO.FIELDS') }}
            </div>
        </div>
        <div v-else class='h-fw-500 h-font-14 h-mb-30'>
            *{{ $t('WORKER.FORM.REQUIRED.FIELDS') }}
        </div>
    </form>
    <div v-if='!loading'
         class='h-width-100p h-flex h-flex-center h-flex-dir-column b-popup-sticky-bottom-buttons'>
        <FwButton
            class='qa-advisor-create-edit-submit'
            size='little'
            :disabled='isDisabledFormButton || isRequestSending'
            borderRadiusType='small-border'
            @click='sendDataToServer'>
            {{ $t('BUTTON.SAVE') }}
        </FwButton>
        <div v-if='!isBranchAdvisorAdd && canDelete'
             class='b-add-branch__form__delete qa-advisor-delete-button'
             @click='$emit(`showDeletePopup`)'>
            {{ $t('ADVISOR.FORM.DELETE.POPUP.BUTTON') }}
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import { namespace } from 'vuex-class';
import { clone } from 'ramda';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';

import { OnboardingApi } from '@/api/onboarding/OnboardingApi';
import { WorkersWebApi } from '@/api/workers/WorkersApi';
import { AdvisorsWebApi } from '@/api/workers/AdvisorsApi';

import { BranchHubsTitlesTypes, CategoryLocationSkillNameType, GeograficCitiesType, CategoryBranchHubType } from '@/types/Appointment';
import { ParsedAddressData } from '@/types/GoogleMap';
import { EditUpdateAdvisorPayloadType, WorkerType } from '@/types/Workers';
import { Country } from '@/types/Account';

import { AddEditGeograficPopup } from '@/components/popups/AddEditGeograficPopup';
import { AutocompleteSelector } from '@/components/nodes/AutocompleteSelector';

import ValidationMixin from '@/mixins/validation';
import TranslateMixin from '@/mixins/TranslateMixin';
import PermissionsMixin from '@/mixins/permissions';
import AccountMixin from '@/mixins/account';

import { isEqual } from '@/helpers/base';
import { getPrettyStringDate } from '@/helpers/dates';
import {
    ON_SITE_ID,
    BY_PHONE_ID,
    IN_BRANCH_BY_ON_SITE,
    IN_BRANCH_VIDEO_ID,
    IN_BRANCH_BY_ONLINE_VIDEO,
    IN_BRANCH_PUBLIC_ID,
} from '@/helpers/appointment';
import { addPostCodeToAddress } from '@/helpers/address';

const AppointmentStore = namespace('AppointmentStore');
const WorkerPageStore = namespace('WorkerPageStore');

@Component({
    components: {
        AddEditGeograficPopup,
        AutocompleteSelector,
    },
    validators: {
        first_name(value: string) {
            return this.requiredValidator({ value });
        },
        last_name(value: string) {
            return this.requiredValidator({ value });
        },
        email(value: string) {
            return this.emailValidator({ value });
        },
    },
})
export default class ConsultBranchesHubsAddAdvisor extends Mixins(ValidationMixin, TranslateMixin, PermissionsMixin, AccountMixin) {
    @WorkerPageStore.Action('storeWorkerData') storeWorkerData!: Function;
    @WorkerPageStore.Mutation('WORKER_DATA') setWorkerData!: Function;
    @WorkerPageStore.Mutation('UPDATE_ADDRESS_POPUP_SHOW') setUpdateAddressShow!: Function;
    @WorkerPageStore.State('workerData') workerData!: WorkerType;
    @WorkerPageStore.State('addressUpdateData') addressUpdateData!: any;
    @AppointmentStore.Mutation('setGeograficCitiesData') setGeograficCitiesData!: Function;
    @AppointmentStore.State('geograficCitiesData') geograficIds!: Array<GeograficCitiesType>;

    @Prop({ type: Boolean, default: false }) readonly isBranchHubHide!: boolean;
    @Prop({ type: Boolean, default: false }) readonly isBranchAdvisorAdd!: boolean;
    @Prop({ type: Boolean, default: false }) readonly isAddEditConsultAdvisors!: boolean;
    @Prop({ type: String, default: null }) readonly type!: string;
    @Prop({ type: String, default: null }) readonly parent_id!: string;

    post_code: string | null = '';
    roleValue: any = null;
    branchValue: CategoryBranchHubType | BranchHubsTitlesTypes | null | undefined = null;
    branchValueFromServer: CategoryBranchHubType | BranchHubsTitlesTypes | null | undefined = null;
    hubValue: CategoryBranchHubType | BranchHubsTitlesTypes | null | undefined = null;
    hubValueFromServer: CategoryBranchHubType | BranchHubsTitlesTypes | null | undefined = null;
    phone: string = '';
    company: string = '';
    code_site: string = '';
    first_name: string = '';
    last_name: string = '';
    email: string = '';
    professional_title: string = '';
    remote_id: string = '';
    errorFromServer: string = '';
    isActiveAdminRequestSending: boolean = false;
    isActiveHubAdmin: boolean = false;
    isActiveBranchAdmin: boolean = false;
    loading: boolean = true;
    isActiveManager: boolean = false;
    isGeograficPopup: boolean = false;
    isRequestSending: boolean = false;
    isBranchAddress: boolean = false;
    isLoadingRoles: boolean = false;
    isInitiaStartPoinDisabled: boolean = false;
    isUpdateAddress: boolean = false;
    isServicePoint: boolean = false;
    serverErrors: serverErrors = {
        first_name: null,
        last_name: null,
        email: this.errorFromServer,
    };

    skills: Array<CategoryLocationSkillNameType> = [];
    roles: Array<CategoryLocationSkillNameType> = [];
    categoryLocationSkills: Array<CategoryLocationSkillNameType> = [];
    skillChoosed: Array<CategoryLocationSkillNameType> = [];
    locationSkillChoosed: Array<CategoryLocationSkillNameType> = [];
    hubs: Array<BranchHubsTitlesTypes> | null = null;
    branches: Array<BranchHubsTitlesTypes> | null = null;
    address: string | null = null;
    startFormData: EditUpdateAdvisorPayloadType | null = null;
    latitude: number | null = null;
    longitude: number | null = null;
    countryString: string = '';
    country: Country | null | undefined = null;

    get isFullFormValid(): boolean {
        return !(
            !this.isFormValid ||
            !this.isAdminFormValid ||
            (this.isActiveManager && !this.roleValue) ||
            (this.isTypeSite && (!this.address && !this.isBranchAddress)) ||
            (this.isOrganizationWithAll && !this.hubValue && !this.branchValue) ||
            (!this.hubValue && !this.branchValue)
        ) && Boolean(this.locationSkillChoosed && this.locationSkillChoosed.length);
    }

    get isDisabledFormButton(): boolean {
        if (!this.isBranchAdvisorAdd) {
            return !this.isFullFormValid || isEqual(this.startFormData, this.advisorPayloadData);
        }
        return !this.isFullFormValid;
    }

    get isAccountOnSiteFlowTypeWithDrive(): boolean {
        return this.user().account_on_site_flow_type === 'with_drive';
    }

    get isAccountOnSiteFlowTypeWithReservedSlots(): boolean {
        return this.user().account_on_site_flow_type === 'with_reserved_slots';
    }

    get servicePointSupport(): boolean {
        return !!this.accountData?.account.service_point_support;
    }

    get isOnSiteFlowDrive(): boolean {
        if (!this.user().account_on_site_flow_type || this.isAccountOnSiteFlowTypeWithReservedSlots) {
            return false;
        }

        return this.isAccountOnSiteFlowTypeWithDrive || (this.workerData && this.isAccountOnSiteFlowTypeWithDrive);
    }

    get categoryLocationSkillsFiltered(): Array<CategoryLocationSkillNameType> {
        const onSiteAvailableTypes = [ON_SITE_ID, BY_PHONE_ID, IN_BRANCH_BY_ON_SITE, IN_BRANCH_BY_ONLINE_VIDEO];

        if (this.calculateRouteForBranchesBooking) {
            onSiteAvailableTypes.push(IN_BRANCH_PUBLIC_ID);
        }

        if (this.isOnSiteFlowDrive) {
            if (this.isTypeSite) {
                return this.categoryLocationSkills.filter(item => onSiteAvailableTypes.includes(item.searchable_name));
            } else if (this.locationSkillChoosed.length > this.locationSkillChoosed.filter(item => onSiteAvailableTypes.includes(item.searchable_name)).length) {
                return this.categoryLocationSkills.filter(item => ![ON_SITE_ID, IN_BRANCH_BY_ON_SITE].includes(item.searchable_name));
            }
        }
        return this.categoryLocationSkills;
    }

    get isAdminFormValid(): boolean {
        return Boolean(
            this.locationSkillChoosed.length !== 0 &&
                (this.geograficIds.length !== 0 || this.isMethodWithOutGeoZone) ||
                (this.geograficIds.length === 0 && this.isMethodWithOutGeoZone)
        );
    }

    get isDeparturePoint(): string | null {
        return this.workerData ? this.workerData.technician_profile.departure_point : null;
    }

    get hubId() {
        if (this.hubValue && this.hubValue.id) {
            return this.hubValue.id;
        }
        return this.hubValue;
    }

    get branchId() {
        if (this.branchValue && this.branchValue.id) {
            return this.branchValue.id;
        }
        return this.branchValue;
    }

    get isTypeSite(): boolean {
        return this.locationSkillChoosed.some(item => item.searchable_name === ON_SITE_ID || item.searchable_name === IN_BRANCH_BY_ON_SITE);
    }

    get isMethodWithOutGeoZone(): boolean {
        const methodsWhichNeedGeoZone = [ON_SITE_ID, IN_BRANCH_BY_ON_SITE, IN_BRANCH_VIDEO_ID, BY_PHONE_ID, IN_BRANCH_BY_ONLINE_VIDEO];
        return !this.locationSkillChoosed.some(item => methodsWhichNeedGeoZone.includes(item.searchable_name));
    }

    get updateDate(): string | null {
        if (this.addressUpdateData) {
            return getPrettyStringDate(new Date(this.addressUpdateData.dt_execute_at), this.$i18n, true);
        }
        return null;
    }

    get sortedHubs(): Array<BranchHubsTitlesTypes> | null {
        return this.hubs ? this.hubs.sort((a, b) => a.name.localeCompare(b.name)) : null;
    }

    get sortedBranches(): Array<BranchHubsTitlesTypes> | null {
        return this.branches ? this.branches.sort((a, b) => a.name.localeCompare(b.name)) : null;
    }

    get isOrganizationWithAll(): boolean {
        return this.user().account_organization_type === `with_all`;
    }

    get isNotOrganizationWithAll(): boolean {
        return this.user().account_organization_type !== `with_all`;
    }

    get isOrganizationWithHubs(): boolean {
        return this.user().account_organization_type === `with_hubs`;
    }

    get isNotOrganizationWithHubs(): boolean {
        return this.user().account_organization_type !== `with_hubs`;
    }

    get isOrganizationWithBranches(): boolean {
        return this.user().account_organization_type === `with_branches`;
    }

    get isOrganizationWithSPAndHubs(): boolean {
        return this.user().account_organization_type === `with_service_points_and_hubs`;
    }

    get isNotOrganizationWithBranches(): boolean {
        return this.user().account_organization_type !== `with_branches`;
    }

    get addressFieldString() {
        let result = null;

        if (!this.isBranchAddress && this.address) {
            if (this.isGeoScopeInternationalWithCountries) {
                result = this.address;
            } else {
                result = addPostCodeToAddress(this.address, this.post_code as string, this.countryString);
            }
        }

        return result;
    }

    get advisorPayloadData(): EditUpdateAdvisorPayloadType {
        let payload = {};
        if (!this.branchValue && this.hubValue) {
            payload = {
                hub_id: this.hubId,
                hub_admin: this.isActiveHubAdmin,
            };
        } else if (this.branchValue && !this.hubValue) {
            payload = {
                branch_site_id: this.branchId,
                branch_admin: this.isActiveBranchAdmin,
            };
        } else if (this.branchValue && this.hubValue) {
            payload = {
                hub_id: this.hubId,
                hub_admin: this.isActiveHubAdmin,
                branch_site_id: this.branchId,
                branch_admin: this.isActiveBranchAdmin,
            };
        }
        if (this.country && this.country.country_iso) {
            payload = {
                ...payload,
                country_iso: this.country.country_iso,
            };
        }
        if (this.latitude && this.longitude) {
            payload = {
                ...payload,
                latitude: this.latitude,
                longitude: this.longitude,
            };
        }
        const rolePayload = this.isActiveManager && this.roleValue ? { role_id: this.roleValue.id } : {};

        return {
            first_name: this.first_name,
            last_name: this.last_name,
            email: this.email,
            professional_title: this.professional_title,
            phone: this.phone,
            kind: 'advisor',
            geo_site_ids: !this.isMethodWithOutGeoZone ? [...this.geograficIds!.map(item => item.id)] : null,
            skill_ids: [...this.skillChoosed!.map(item => item.id), ...this.locationSkillChoosed!.map(item => item.id)],
            departure_point: this.addressFieldString,
            set_branch_as_starting_point: !!this.isBranchAddress,
            is_manager: this.isActiveManager,
            remote_id: this.remote_id,
            ...(this.code_site && { code_site: this.code_site }),
            ...rolePayload,
            ...payload,
            ...(this.servicePointSupport && { service_point_support: this.isServicePoint }),
        };
    }

    toggleService(): void {
        this.isServicePoint = !this.isServicePoint;
    }

    closePopup(): void {
        this.isGeograficPopup = false;
    }

    input(parsedAddressData: ParsedAddressData): void {
        if (parsedAddressData) {
            this.post_code = parsedAddressData.post_code;
            this.address = parsedAddressData.calizyAddress;
            this.countryString = parsedAddressData.country;
            if (parsedAddressData.longitude && parsedAddressData.latitude) {
                this.longitude = parsedAddressData.longitude;
                this.latitude = parsedAddressData.latitude;
            }
        }
    }

    deleteItem(item: GeograficCitiesType): void {
        if (item) {
            const arrayItems = this.geograficIds.filter(filterItem => filterItem.id !== item.id);
            this.setGeograficCitiesData(arrayItems);
        }
    }

    toggleAdmin(): void {
        this.isActiveManager = !this.isActiveManager;
        if (this.isActiveManager && !this.roleValue) {
            this.getRoles();
        }
    }

    getTitles(): void {
        if (((this.isOrganizationWithHubs && !this.hubValue && !this.branchValue) ||
            (this.hubValue && this.isOrganizationWithBranches) ||
            (this.hubValue && this.isOrganizationWithHubs))) {
            if (this.isBranchHubHide && this.$route.params.id && this.hubs) {
                this.hubValue = this.hubs.find(item => this.$route.params.id === item.id);
            }
        } if (((this.isOrganizationWithBranches && !this.hubValue && !this.branchValue) ||
            (this.branchValue && this.isOrganizationWithBranches) ||
            (this.branchValue && this.isOrganizationWithHubs))) {
            if (this.isBranchHubHide && this.$route.params.id && this.branches) {
                this.branchValue = this.branches.find(item => this.$route.params.id === item.id);
            }
        } if (this.isOrganizationWithAll) {
            if (this.isBranchHubHide && this.$route.params.id) {
                if (this.branches) {
                    this.branchValue = this.branches.find(item => this.$route.params.id === item.id) || null;
                }
                if (this.hubs) {
                    this.hubValue = this.hubs.find(item => this.$route.params.id === item.id) || null;
                }
            }
        }
    }

    async getRoles(): Promise<void> {
        this.isLoadingRoles = true;
        const { role_name } = await OnboardingApi.getFrontHelpers('role_names');
        this.roles = role_name;
        this.isLoadingRoles = false;
    }

    async sendDataToServer() {
        this.isRequestSending = true;
        if (this.isUpdateAddress && this.isTypeSite) {
            this.setUpdateAddressShow(this.isUpdateAddress);
        }

        try {
            if (!this.isBranchAdvisorAdd) {
                try {
                    const { data } = await AdvisorsWebApi.updateAdvisor(this.advisorPayloadData, this.$route.params.id);
                    this.setWorkerData(data.advisor);
                    this.sentNotif('NOTIFICATION.BAR.SUCCESS.UPDATED', true);
                    this.$emit('closeAdvisorPopup');
                    // @ts-ignore-next-line
                } catch ({ response }) {
                    this.sentNotif(response);
                }
            } else {
                try {
                    const { data } = await AdvisorsWebApi.createAdvisor(this.advisorPayloadData);
                    this.sentNotif('NOTIFICATION.BAR.SUCCESS.CREATED', true);
                    if (this.$route.meta && this.$route.meta.isMainComponent) {
                        this.$router.push({ path: `/consult/advisor/${data.advisor.id}` });
                    } else {
                        this.$emit('closeAdvisorPopup', data.advisor);
                    }
                } catch ({ response }) {
                    this.sentNotif(response);
                }
            }
        } catch (e) {
            console.log(e);
            // this.errorFromServer = response.data.errors[0];
        } finally {
            this.isRequestSending = false;
        }
    }

    async created() {
        const { data } = await WorkersWebApi.getAdvisorHelpersData();
        if (!this.skills.length) {
            this.skills = data.skill_names;
        }
        this.categoryLocationSkills = data.category_location_skill_names;
        this.hubs = data.hub_name;
        this.branches = data.branch_titles;
        this.setGeograficCitiesData([]);
        this.loading = true;
        if (!this.isBranchAdvisorAdd) {
            this.setUpdateAddressShow(false);
            const existUser = { data: { advisor: this.workerData }};
            // eslint-disable-next-line
            const { data } = this.workerData ? existUser : await AdvisorsWebApi.getAdvisorData({
                id: this.$router.currentRoute.params.id,
            });
            this.setGeograficCitiesData(data.advisor.geo_sites);
            if ((data.advisor as WorkerType)) {
                this.first_name = data.advisor.first_name;
                this.last_name = data.advisor.last_name;
                this.isActiveManager = !!data.advisor.is_manager;
                this.email = data.advisor.email;
                this.remote_id = data.advisor.technician_profile.remote_id;
                this.professional_title = data.advisor.professional_title;
                this.isActiveAdmin = data.advisor.is_company_admin_set;
                this.isServicePoint = data.advisor.technician_profile.service_point_support;
                this.phone = data.advisor.phone || data.advisor.technician_profile.phone;
                this.code_site = data.advisor.code_site;
                this.isActiveHubAdmin = data.advisor.technician_profile.hub_admin;
                this.isActiveBranchAdmin = data.advisor.technician_profile.branch_admin;
                this.skillChoosed = this.skills.filter(item => {
                    return data.advisor.skills.find((skill: CategoryLocationSkillNameType) => skill.id === item.id);
                });
                if (data.advisor.branch && data.advisor.branch.address) {
                    if (data.advisor.technician_profile && data.advisor.technician_profile.departure_point) {
                        if (data.advisor.branch.address === data.advisor.technician_profile.departure_point) {
                            this.isBranchAddress = true;
                        }
                    }
                }
                if (data.advisor.technician_profile && data.advisor.technician_profile.departure_point) {
                    this.address = data.advisor.technician_profile.departure_point;
                    this.isInitiaStartPoinDisabled = true;
                }
                this.locationSkillChoosed = this.categoryLocationSkills.filter(item => {
                    return data.advisor.skills.find((skill: CategoryLocationSkillNameType) => skill.id === item.id);
                });
                if (data.advisor.manager_profile) {
                    await this.getRoles();
                    this.isActiveManager = true;
                    this.roleValue = this.roles.filter(item => {
                        return data.advisor.manager_profile.role.id === item.id;
                    });
                }
                if (data.advisor.branch) {
                    this.branchValue = data.advisor.branch;
                    this.branchValueFromServer = data.advisor.branch;
                }
                if (data.advisor.hub) {
                    this.hubValue = data.advisor.hub;
                    this.hubValueFromServer = data.advisor.hub;
                }
                if (data.advisor.branch && data.advisor.hub) {
                    this.branchValue = data.advisor.branch;
                    this.branchValueFromServer = data.advisor.branch;
                    this.hubValue = data.advisor.hub;
                    this.hubValueFromServer = data.advisor.hub;
                }
                if (this.isGeoScopeInternationalWithCountries && data.advisor && data.advisor.technician_profile && data.advisor.technician_profile.country_iso) {
                    this.country = (this.geoScopeCountries as Array<Country>).find(country => country.country_iso === data.advisor.technician_profile.country_iso);
                }
            }
        } else if (this.type === `branch`) {
            // @ts-ignore-next-line
            this.branchValue = this.branches.find(item => this.parent_id === item.id) || null;
        } else if (this.type === `hub`) {
            // @ts-ignore-next-line
            this.hubValue = this.hubs.find(item => this.parent_id === item.id) || null;
        } else if (this.isAddEditConsultAdvisors) {
            this.branchValue = null;
            this.hubValue = null;
        }
        this.getTitles();
        this.startFormData = clone(this.advisorPayloadData);
        this.loading = false;
    }

    @Watch('errorFromServer')
    checkEmailError(): void {
        if (this.errorFromServer.includes('Email')) {
            this.serverErrors = {
                // @ts-ignore-next-line
                email: this.$i18n.t('WORKER.EMAIL.TAKEN'),
            };
        }
    }
}
</script>

<style lang='sass'>
.b-add-worker
    &__wrapper
        &-form
            margin-bottom: 100px

        .multiselect__input
            z-index: -1

        .multiselect__placeholder
            opacity: 1
            z-index: 2

        &-address
            .b-select-address
                position: unset!important
            .multiselect
                position: unset!important
            .multiselect__content-wrapper
                max-height: 200px!important
                left: 0
                top: 70px!important

        .b-label-card
            background-color: #fff
            height: 60px !important

    &__required-fields
        display: flex
        align-items: center
        justify-content: flex-end
        font-weight: 500
        color: #213F6B
        height: 60px
        margin-top: 26px

    &__is_admin_active
        font-weight: 500

.b-update-address__checkbox
    .icon-white__background--transparent
        padding: 0 !important

    .icon-white__background p, .icon-white__background--transparent p
        max-width: 100% !important
        padding-right: 10px

.b-add-worker
    &__wrapper__line
        display: flex
        justify-content: space-between
        padding-bottom: 30px

        @include media('<=phone')
            flex-direction: column
            padding-bottom: 0

            .fw-form-input__wrapper
                margin-right: 0
                margin-bottom: 15px

    &__input-wrapper
        padding-bottom: 30px

        @include media('<=phone')
            padding-bottom: 0

.b-add-worker__input__inner
    @include media('>phone')
        width: 310px

.b-add-worker__input__280
    @include media('>phone')
        width: 280px !important
</style>
