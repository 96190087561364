import { Route } from 'vue-router';
import { AppointmentWrapper } from '@/views/appointment/appointment/AppointmentWrapper';
import { AppointmentSelectLocationPage } from '@/views/planAppointment/AppointmentSelectLocationPage';
import { AddAppointmentTypePage } from '@/views/planAppointment/AddAppointmentTypePage';
import { AppointmentChooseAddressPage } from '@/views/planAppointment/AppointmentChooseAddressPage';
import { AppointmentChooseClientAddressPage } from '@/views/planAppointment/AppointmentChooseClientAddressPage';
import { AppointmentPostcodePage } from '@/views/planAppointment/AppointmentPostcodePage';
import { AppointmentClientPostcodePage } from '@/views/planAppointment/AppointmentClientPostcodePage';
import { AppointmentChooseBranchPage } from '@/views/planAppointment/AppointmentChooseBranchPage';
import { CustomDetailsTypePage } from '@/views/planAppointment/CustomDetailsTypePage';
import { AppointmentChooseBranchOfficePage } from '@/views/planAppointment/AppointmentChooseBranchOfficePage';
import { ConsultChoosePage } from '@/views/planAppointment/ConsultChoosePage';
import { ConsultBranchPage } from '@/views/consult/branches/ConsultBranchPage';
import { ConsultBranchStatisticPage } from '@/views/consult/branches/ConsultBranchStatisticPage';
import { ConsultBranchesPage } from '@/views/consult/branches/ConsultBranchesPage';
import { ConsultHubPage } from '@/views/consult/hubs/ConsultHubPage';
import { ConsultHubsPage } from '@/views/consult/hubs/ConsultHubsPage';
import { ConsultHubStatisticPage } from '@/views/consult/hubs/ConsultHubStatisticPage';
import { ConsultCompanyPage } from '@/views/consult/companies/ConsultCompanyPage';
import { ConsultCompaniesPage } from '@/views/consult/companies/ConsultCompaniesPage';
import { ConsultAppointmentsPage } from '@/views/consult/branches/ConsultAppointmentsPage';
import { AddOrEditBranchByForm } from '@/views/consult/add-branches/AddOrEditBranchByForm';
import { AddOrEditHubsByForm } from '@/views/consult/add-hubs/AddOrEditHubsByForm';
import { AddOrEditCompanyByForm } from '@/views/consult/add-companies/AddOrEditCompanyByForm';
import { ConsultWorkersPage } from '@/views/consult/workers/tech/ConsultWorkersPage';
import { ConsultAdvisorPage } from '@/views/consult/workers/advisor/ConsultAdvisorPage';
import { ConsultAdvisorsPage } from '@/views/consult/workers/advisor/ConsultAdvisorsPage';
import { ConsultWorkerPage } from '@/views/consult/workers/tech/ConsultWorkerPage';
import { AdvancedWorkerSearch } from '@/views/consult/workers/AdvancedWorkerSearch';
import { CalendarPage } from '@/views/consult/common/CalendarPage';
import { BOOK_CALENDAR_MAPPING } from '@/helpers/calendar';
import { AddEditAdvisorPopup } from '@/components/popups/AddEditAdvisorPopup';
import { AddEditWorkerPopup } from '@/components/popups/AddEditWorkerPopup';
import { AppointmentBranchAddRoom } from '@/views/appointment/AppointmentBranchAddRoom';
import { AddOrEditAppointmentByFormAsPage } from '@/views/appointment/AddOrEditAppointmentByFormAsPage';
import childrenBranchStatisticRoutes from '@/routes/planAppointment/childrenBranchStatisticRoutes';
import childrenHubStatisticRoutes from '@/routes/planAppointment/childrenHubStatisticRoutes';
import { RIGHT_LIST, RIGHT_READ, RIGHT_CREATE, RIGHT_UPDATE, CONSULT } from '@/helpers/rights';
import { RouteType } from '@/types/router';
import { APPOINTMENT_CALENDAR_PAGE_NAME } from '@/helpers/consts';
import { ConsultServicePointsPage } from '@/views/consult/service-points/ConsultServicePointsPage';
import { ConsultServicePointPage } from '@/views/consult/service-points/ConsultServicePointPage';
import { ConsultServicePointShiftPage } from '@/views/consult/service-points/ConsultServicePointShiftPage';

const planAppointmentRoutes: Array<RouteType> = [
    {
        path: `/appointment`,
        component: AppointmentWrapper,
        name: `AppointmentWrapper`,
        children: [
            {
                path: '/appointment/matmut/fetch-details',
                name: 'CustomDetailsMatmutTypePage',
                component: CustomDetailsTypePage,
                redirect: '/appointment/fetch-details',
                meta: {
                    backButton: false,
                    nextRouteName: CustomDetailsTypePage.name,
                    breadCrumbsFlowKey: `appointment-matmut`,
                    breadCrumbsText: `BREADCRUMBS.CUSTOM.DETAILS`,
                    saveBreadCrumbsFlowKey: true,
                    rightBlock: 'appointment_rights',
                    right: RIGHT_CREATE,
                },
            },
            {
                path: '/appointment/fetch-details',
                name: CustomDetailsTypePage.name,
                component: CustomDetailsTypePage,
                meta: {
                    backButton: false,
                    nextRouteName: CustomDetailsTypePage.name,
                    breadCrumbsFlowKey: `appointment-matmut`,
                    breadCrumbsText: `BREADCRUMBS.CUSTOM.DETAILS`,
                    saveBreadCrumbsFlowKey: true,
                    rightBlock: 'appointment_rights',
                    right: RIGHT_CREATE,
                },
            },
            {
                path: '/appointment/select-type',
                name: AddAppointmentTypePage.name,
                component: AddAppointmentTypePage,
                meta: {
                    backButton: false,
                    nextRouteName: AppointmentSelectLocationPage.name,
                    breadCrumbsFlowKey: `appointment-matmut`,
                    breadCrumbsText: `LABEL.APPOINTMENT.TYPE`,
                    saveBreadCrumbsFlowKey: true,
                    rightBlock: 'appointment_rights',
                    right: RIGHT_CREATE,
                    rights: [RIGHT_CREATE, RIGHT_UPDATE],
                },
            },
            {
                path: '/appointment/partners-location',
                name: AppointmentSelectLocationPage.name,
                component: AppointmentSelectLocationPage,
                meta: {
                    backButton: true,
                    breadCrumbsFlowKey: `appointment-matmut`,
                    breadCrumbsText: `BREADCRUMBS.LOCATION`,
                    saveBreadCrumbsFlowKey: true,
                    rightBlock: 'appointment_rights',
                    right: RIGHT_CREATE,
                    rights: [RIGHT_CREATE, RIGHT_UPDATE],
                },
            },
            {
                path: '/appointment/address/on-site',
                name: `${AppointmentChooseAddressPage.name}-on-site`,
                component: AppointmentChooseAddressPage,
                meta: {
                    backButton: true,
                    breadCrumbsFlowKey: `appointment-matmut`,
                    breadCrumbsText: `BREADCRUMBS.ADDRESS`,
                    saveBreadCrumbsFlowKey: true,
                    onSite: true,
                    rightBlock: 'appointment_rights',
                    right: RIGHT_CREATE,
                    rights: [RIGHT_CREATE, RIGHT_UPDATE],
                },
            },
            {
                path: '/appointment/address/client/on-site',
                name: `${AppointmentChooseClientAddressPage.name}-on-site`,
                component: AppointmentChooseClientAddressPage,
                meta: {
                    backButton: true,
                    breadCrumbsFlowKey: `appointment-matmut`,
                    breadCrumbsText: `BREADCRUMBS.ADDRESS`,
                    saveBreadCrumbsFlowKey: true,
                    onSite: true,
                    rightBlock: 'appointment_rights',
                    right: RIGHT_CREATE,
                    rights: [RIGHT_CREATE, RIGHT_UPDATE],
                },
            },
            {
                path: '/appointment/address/in-branch-on-site',
                name: `${AppointmentChooseAddressPage.name}-in-branch-on-site`,
                component: AppointmentChooseAddressPage,
                meta: {
                    backButton: true,
                    breadCrumbsFlowKey: `appointment-matmut`,
                    breadCrumbsText: `BREADCRUMBS.ADDRESS`,
                    saveBreadCrumbsFlowKey: true,
                    onSite: false,
                    onSiteInBranch: true,
                    rightBlock: 'appointment_rights',
                    right: RIGHT_CREATE,
                    rights: [RIGHT_CREATE, RIGHT_UPDATE],
                },
            },
            {
                path: '/appointment/address/client/in-branch-on-site',
                name: `${AppointmentChooseClientAddressPage.name}-branch-on-site`,
                component: AppointmentChooseClientAddressPage,
                meta: {
                    backButton: true,
                    breadCrumbsFlowKey: `appointment-matmut`,
                    breadCrumbsText: `BREADCRUMBS.ADDRESS`,
                    saveBreadCrumbsFlowKey: true,
                    onSite: false,
                    onSiteInBranch: true,
                    rightBlock: 'appointment_rights',
                    right: RIGHT_CREATE,
                    rights: [RIGHT_CREATE, RIGHT_UPDATE],
                },
            },
            // {
            //     path: '/appointment/address',
            //     name: AppointmentChooseAddressPage.name,
            //     component: AppointmentChooseAddressPage,
            //     meta: {
            //         backButton: true,
            //         breadCrumbsFlowKey: `appointment-matmut`,
            //         breadCrumbsText: `BREADCRUMBS.ADDRESS`,
            //         saveBreadCrumbsFlowKey: true,
            //         redirectIfUserWasInCalendar: true,
            //         rightBlock: 'appointment_rights',
            //         right: RIGHT_CREATE,
            //     },
            // },
            {
                path: '/appointment/choose-branch',
                name: AppointmentChooseBranchPage.name,
                component: AppointmentChooseBranchPage,
                meta: {
                    backButton: true,
                    breadCrumbsFlowKey: `appointment-matmut`,
                    breadCrumbsText: `BREADCRUMBS.ADDRESS`,
                    saveBreadCrumbsFlowKey: true,
                    rightBlock: 'appointment_rights',
                    right: RIGHT_CREATE,
                    rights: [RIGHT_CREATE, RIGHT_UPDATE],
                },
            },
            {
                path: '/appointment/postcode',
                name: AppointmentPostcodePage.name,
                component: AppointmentPostcodePage,
                meta: {
                    backButton: true,
                    breadCrumbsFlowKey: `appointment-matmut`,
                    breadCrumbsText: `BREADCRUMBS.ADDRESS`,
                    saveBreadCrumbsFlowKey: true,
                    rightBlock: 'appointment_rights',
                    right: RIGHT_CREATE,
                    rights: [RIGHT_CREATE, RIGHT_UPDATE],
                },
            },
            {
                path: '/appointment/client/postcode',
                name: AppointmentClientPostcodePage.name,
                component: AppointmentClientPostcodePage,
                meta: {
                    backButton: true,
                    breadCrumbsFlowKey: `appointment-matmut`,
                    breadCrumbsText: `BREADCRUMBS.ADDRESS`,
                    saveBreadCrumbsFlowKey: true,
                    rightBlock: 'appointment_rights',
                    right: RIGHT_CREATE,
                    rights: [RIGHT_CREATE, RIGHT_UPDATE],
                },
            },
            {
                path: '/appointment/address/branch',
                name: AppointmentChooseBranchOfficePage.name,
                component: AppointmentChooseBranchOfficePage,
                meta: {
                    backButton: true,
                    breadCrumbsFlowKey: `appointment-matmut`,
                    breadCrumbsText: `BREADCRUMBS.ADDRESS`,
                    saveBreadCrumbsFlowKey: true,
                    rightBlock: 'appointment_rights',
                    right: RIGHT_CREATE,
                    rights: [RIGHT_CREATE, RIGHT_UPDATE],
                },
            },
            {
                path: `/appointment/calendar/:type(${Object.keys(BOOK_CALENDAR_MAPPING).join(`|`)})`,
                component: CalendarPage,
                name: APPOINTMENT_CALENDAR_PAGE_NAME,
                meta: {
                    showAppHeader: false,
                    showAppHeaderProgress: false,
                    getName: (route: Route) => BOOK_CALENDAR_MAPPING[route.params.type],
                    breadCrumbsFlowKey: `appointment-matmut`,
                    breadCrumbsText: `BREADCRUMBS.ADDRESS`,
                    saveBreadCrumbsFlowKey: true,
                    hideBreadCrumbs: true,
                    rightBlock: 'appointment_rights',
                    right: RIGHT_CREATE,
                    rights: [RIGHT_CREATE, RIGHT_UPDATE],
                },
            },
        ],
    },
    {
        path: `/appointment/matmut/:id`,
        component: AddOrEditAppointmentByFormAsPage,
        name: `AddOrEditAppointmentByFormAsPage`,
        meta: {
            showAppHeader: true,
            showAppHeaderProgress: true,
            rightBlockType: 'appointment_rights',
            right: RIGHT_READ,
            rights: [RIGHT_CREATE, RIGHT_UPDATE],
        },
    },
    {
        path: `/consult/choose`,
        component: ConsultChoosePage,
        name: `ConsultChoosePage`,
        meta: {
            showAppHeader: true,
            showAppHeaderProgress: true,
            pageType: CONSULT,
        },
    },
    {
        path: `/consult/choose/branch/:page([0-9]+)?`,
        component: ConsultBranchesPage,
        name: `ConsultBranchesPage`,
        meta: {
            showAppHeader: true,
            showAppHeaderProgress: true,
            isMainComponent: true,
            rightBlock: 'branch_rights',
            right: RIGHT_LIST,
        },
    },
    {
        path: '/consult/branch/add-rooms/:id',
        component: AppointmentBranchAddRoom,
        meta: {
            showAppHeader: true,
            showAppHeaderProgress: true,
            part: 'consult',
            nextUrl: `/consult/branch/:id`,
            rightBlock: 'branch_rights',
            right: RIGHT_UPDATE,
        },
    },
    {
        path: `/consult/branch/:id`,
        component: ConsultBranchPage,
        name: `ConsultBranchPage`,
        meta: {
            showAppHeader: true,
            showAppHeaderProgress: true,
            isConsultBranch: true,
            rightBlock: 'branch_rights',
            right: RIGHT_READ,
        },
    },
    {
        path: `/consult/branch/statistic/:id`,
        component: ConsultBranchStatisticPage,
        name: `ConsultBranchStatisticPage`,
        meta: {
            showAppHeader: true,
            showAppHeaderProgress: true,
            isConsultBranch: true,
            rightBlock: 'kpis_rights',
            right: RIGHT_READ,
        },
        children: childrenBranchStatisticRoutes,
    },
    {
        path: `/consult/branch/edit/:id`,
        component: AddOrEditBranchByForm,
        meta: {
            showAppHeader: false,
            showAppHeaderProgress: true,
            rightBlock: 'branch_rights',
            right: RIGHT_UPDATE,
        },
    },
    {
        path: `/consult/branch/add/new`,
        component: AddOrEditBranchByForm,
        meta: {
            showAppHeader: true,
            showAppHeaderProgress: true,
            rightBlock: 'branch_rights',
            right: RIGHT_CREATE,
        },
    },
    {
        path: `/consult/choose/advisors/search`,
        component: AdvancedWorkerSearch,
        name: `AdvancedWorkerSearch`,
        meta: {
            showAppHeader: true,
            showAppHeaderProgress: true,
            isMainComponent: true,
            rightBlock: 'advisor_rights',
            right: RIGHT_LIST,
        },
    },
    {
        path: `/consult/choose/managers/search`,
        component: AdvancedWorkerSearch,
        name: `AdvancedManagerSearch`,
        meta: {
            showAppHeader: true,
            showAppHeaderProgress: true,
            isMainComponent: true,
            rightBlock: 'manager_rights',
            right: RIGHT_LIST,
        },
    },
    {
        path: `/consult/choose/advisors/:page([0-9]+)?`,
        component: ConsultAdvisorsPage,
        name: `ConsultAdvisorsPage`,
        meta: {
            showAppHeader: true,
            showAppHeaderProgress: true,
            isMainComponent: true,
            rightBlock: 'advisor_rights',
            right: RIGHT_LIST,
        },
    },
    {
        path: `/consult/advisor/:id`,
        component: ConsultAdvisorPage,
        name: `ConsultAdvisorPage`,
        meta: {
            showAppHeader: true,
            showAppHeaderProgress: true,
            rightBlock: 'advisor_rights',
            right: RIGHT_READ,
        },
    },
    // {
    //     path: `/consult/advisor/edit/:id`,
    //     component: AddOrEditWorkerByForm,
    //     name: `AddOrEditWorkerByForm`,
    //     meta: {
    //         showAppHeader: true,
    //         showAppHeaderProgress: true,
    //         rightBlock: 'advisor_rights',
    //         right: RIGHT_UPDATE,
    //     },
    // },
    {
        path: `/consult/advisors/add/new`,
        component: AddEditAdvisorPopup,
        name: `AddAdvisorPopup`,
        meta: {
            showAppHeader: true,
            showAppHeaderProgress: true,
            rightBlock: 'advisor_rights',
            right: RIGHT_CREATE,
        },
    },
    {
        path: `/consult/advisors/edit/:id`,
        component: AddEditAdvisorPopup,
        name: `EditAdvisorPopup`,
        meta: {
            showAppHeader: true,
            showAppHeaderProgress: true,
            rightBlock: 'advisor_rights',
            right: RIGHT_UPDATE,
        },
    },
    {
        path: `/consult/choose/technicians/:page([0-9]+)?`,
        component: ConsultWorkersPage,
        name: `ConsultWorkersPage`,
        meta: {
            showAppHeader: true,
            showAppHeaderProgress: true,
            isMainComponent: true,
            rightBlock: 'technician_rights',
            right: RIGHT_LIST,
        },
    },
    {
        path: '/consult/technician/:id',
        component: ConsultWorkerPage,
        name: `ConsultWorkerPage`,
        meta: {
            showAppHeader: true,
            showAppHeaderProgress: true,
            rightBlock: 'technician_rights',
            right: RIGHT_READ,
        },
    },
    {
        path: `/consult/technicians/edit/:id`,
        component: AddEditWorkerPopup,
        meta: {
            showAppHeader: true,
            showAppHeaderProgress: true,
            rightBlock: 'technician_rights',
            right: RIGHT_UPDATE,
        },
    },
    {
        path: `/consult/technicians/add/new`,
        component: AddEditWorkerPopup,
        meta: {
            showAppHeader: true,
            showAppHeaderProgress: true,
            rightBlock: 'technician_rights',
            right: RIGHT_CREATE,
        },
    },
    {
        path: `/consult/choose/hubs/:page([0-9]+)?`,
        component: ConsultHubsPage,
        name: `ConsultHubsPage`,
        meta: {
            showAppHeader: true,
            showAppHeaderProgress: true,
            isMainComponent: true,
            rightBlock: 'hub_rights',
            right: RIGHT_LIST,
        },
    },
    {
        path: `/consult/hub/:id`,
        component: ConsultHubPage,
        name: `ConsultHubPage`,
        meta: {
            showAppHeader: true,
            showAppHeaderProgress: true,
            rightBlock: 'hub_rights',
            right: RIGHT_READ,
        },
    },
    {
        path: `/consult/hub/statistic/:id`,
        component: ConsultHubStatisticPage,
        name: `ConsultHubStatisticPage`,
        meta: {
            showAppHeader: true,
            showAppHeaderProgress: true,
            isConsultBranch: true,
            rightBlock: 'kpis_rights',
            right: RIGHT_READ,
        },
        children: childrenHubStatisticRoutes,
    },
    {
        path: `/consult/hub/add/new`,
        component: AddOrEditHubsByForm,
        meta: {
            showAppHeader: true,
            showAppHeaderProgress: true,
            rightBlock: 'hub_rights',
            right: RIGHT_CREATE,
        },
    },
    {
        path: `/consult/hub/edit/:id`,
        component: AddOrEditHubsByForm,
        meta: {
            showAppHeader: false,
            showAppHeaderProgress: true,
            rightBlock: 'hub_rights',
            right: RIGHT_UPDATE,
        },
    },
    {
        path: `/consult/choose/companies/:page([0-9]+)?`,
        component: ConsultCompaniesPage,
        name: `ConsultCompaniesPage`,
        meta: {
            showAppHeader: true,
            showAppHeaderProgress: true,
            isMainComponent: true,
            rightBlock: 'company_rights',
            right: RIGHT_LIST,
        },
    },
    {
        path: '/consult/company/:id',
        component: ConsultCompanyPage,
        name: `ConsultCompanyPage`,
        meta: {
            showAppHeader: true,
            showAppHeaderProgress: true,
            rightBlock: 'company_rights',
            right: RIGHT_READ,
        },
    },
    {
        path: `/consult/company/edit/:id`,
        component: AddOrEditCompanyByForm,
        meta: {
            showAppHeader: true,
            showAppHeaderProgress: true,
            rightBlock: 'company_rights',
            right: RIGHT_UPDATE,
        },
    },
    {
        path: `/consult/company/add/new`,
        component: AddOrEditCompanyByForm,
        meta: {
            showAppHeader: true,
            showAppHeaderProgress: true,
            rightBlock: 'company_rights',
            right: RIGHT_CREATE,
        },
    },
    {
        path: `/consult/choose/appointments`,
        component: ConsultAppointmentsPage,
        name: `ConsultAppointmentsPage`,
        meta: {
            showAppHeader: true,
            showAppHeaderProgress: true,
            isMainComponent: true,
            rightBlock: 'appointment_rights',
            right: RIGHT_LIST,
        },
    },
    {
        path: `/consult/choose/service-points/:page([0-9]+)?`,
        component: ConsultServicePointsPage,
        name: `ConsultServicePointsPage`,
        meta: {
            showAppHeader: true,
            showAppHeaderProgress: true,
            isMainComponent: true,
            rightBlock: 'service_point_rights',
            right: RIGHT_LIST,
        },
    },
    {
        path: `/consult/service-point/shift/:id`,
        component: ConsultServicePointShiftPage,
        name: `ConsultServicePointShiftPage`,
        meta: {
            showAppHeader: true,
            showAppHeaderProgress: true,
            isMainComponent: true,
            rightBlock: 'shift_rights',
            right: RIGHT_READ,
        },
    },
    {
        path: `/consult/service-point/:id`,
        component: ConsultServicePointPage,
        name: `service_point_rights`,
        meta: {
            showAppHeader: true,
            showAppHeaderProgress: true,
            isMainComponent: true,
            rightBlock: 'service_point_rights',
            right: RIGHT_READ,
        },
    },
];
const appointmentPlanChildren = planAppointmentRoutes[0].children;
if (appointmentPlanChildren) {
    for (let i = 0; i < appointmentPlanChildren.length; i++) {
        const appointmentPlanChildrenItem = appointmentPlanChildren[i];
        if (!appointmentPlanChildrenItem.meta) {
            appointmentPlanChildrenItem.meta = {};
        }
        if (appointmentPlanChildrenItem.meta!.showAppHeader !== false) {
            appointmentPlanChildrenItem.meta!.showAppHeader = true;
        }
        if (appointmentPlanChildrenItem.meta!.showAppHeaderProgress !== false) {
            appointmentPlanChildrenItem.meta!.showAppHeaderProgress = true;
        }
    }
}

export default planAppointmentRoutes;
