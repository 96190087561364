<template>
<FwSpinLoader
    v-if='loading'
    :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
    class='h-flex-center loader'
    :isActive='loading'
    className='h-p-20'>
</FwSpinLoader>
<div v-else class='b-page-container b-consult-worker'>
    <div class='b-consult-card__inner'>
        <GoBackButton
            class='h-mt-30'
            isRelativePosition>
        </GoBackButton>
    </div>
    <div class='b-common-item-title-wrapper'>
        <div class='h-flex h-width-100p'>
            <WorkerAvatar v-if='!loading' :worker='workerData' :hideAvatar='true' ></WorkerAvatar>
            <div v-if='!loading && workerData && workerData.technician_profile.company_admin'
                 class='b-worker-panel__admin h-font-12 h-fw-700 h-ml-30 h-flex-center'>
                ADMIN
            </div>
        </div>
        <div class='b-common-item-title-wrapper__inner'>
            <FwButton
                v-if='!loading && currentUserRole && currentUserRole.calendar_profile_rights.read'
                :to='`/consult/calendar/user/${$route.params.id}`'
                class='h-white-space-nowrap'
                size='little'
                fontSize='12px'
                borderRadiusType='small-border'>
                {{ $t('BUTTON.SEE_CALENDAR') }}
            </FwButton>
        </div>
    </div>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <div v-else class='h-ph-10'>
        <div class='b-consult-card__wrapper'>
            <div class='b-consult-cards__wrapper-main h-flex h-flex-dir-column'>
                <div class='b-consult-card b-consult-card--layout'>
                    <div class='b-consult-card__title h-pos-abs'>
                        <FwIcon
                            v-if='canEdit'
                            class='h-pointer qa-advisor-page-edit-button'
                            icon='edit-settings'
                            size='25'
                            color='#BEC7D4'
                            @click.native='isAdvisorEditPopup = true'>
                        </FwIcon>
                    </div>
                    <div class='b-consult-card__inner b-consult-card__inner--no-padding b-consult-card__scroll-inner'>
                        <ConsultCard
                            v-for='information in prepareData(workerData)'
                            :key='information.title'
                            :information='information'>
                        </ConsultCard>
                    </div>
                </div>
                <div v-if='showGeoZoneReassignments || isUrlBuilderAvailable'
                     class='b-consult-card b-consult-mt b-consult-card--wh'>
                    <div>
                        <div class='h-flex h-flex-space-between h-flex-center'>
                            <div class='h-font-20 h-fw-700 h-mv-10'>
                                {{ $t('ACTIONS.LABEL') }}
                            </div>
                        </div>
                        <template v-if='showGeoZoneReassignments'>
                            <div class='b-consult-card__sub-aside h-flex h-flex-space-between h-flex-center h-pointer qa-advisor-geo-zone-card'
                                 @click='isGeoZoneReassignmentsPopupShow = true'>
                                {{ $t('ADVISOR.GEO_ZONE_SHORT') }}
                                <div class='b-consult-card__worker-arrow'>
                                    <FwIcon
                                        class='h-mh-5'
                                        icon='arrow-right'
                                        size='14'
                                        color='#27dbbd'>
                                    </FwIcon>
                                </div>
                            </div>
                        </template>
                        <div v-if='isUrlBuilderAvailable'
                             class='b-consult-card__sub-aside h-flex h-flex-space-between h-flex-center h-pointer qa-advisor-url-builder-card'
                             @click='isSelfcareUrlsPopupShow = true'>
                            {{ $tc('ADVISOR.SELFCARE.LINKS.PLURAL', 1) }}
                            <div class='b-consult-card__worker-arrow'>
                                <FwIcon
                                    class='h-mh-5'
                                    icon='arrow-right'
                                    size='14'
                                    color='#27dbbd'>
                                </FwIcon>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if='workerData && workerData.technician_profile.departure_point && (isOnSite || isOnSiteInBranch)'
                     class='b-consult-card b-consult-mt'>
                    <div class='h-pl-30 h-pv-20 h-pr-20'>
                        <div class='h-flex h-flex-space-between h-flex-center'>
                            <div class='h-font-20 h-fw-700 h-mv-10'>
                                {{ $t('LABEL.START.POINT') }}
                            </div>
                            <FwIcon
                                v-if='canEdit'
                                class='h-pointer'
                                icon='edit-settings'
                                size='25'
                                color='#BEC7D4'
                                @click.native='goToAdvisorAddress'>
                            </FwIcon>
                        </div>
                        <div class='b-consult-card__address h-fw-500'>
                            {{ $t('COMPANIES.FORM.INPUT.ADDRESS.LABEL') }}
                        </div>
                        <div class='b-consult-card__sub-aside'>
                            {{ workerData.technician_profile.departure_point }}
                        </div>
                        <WorkingHoursUpdateInfo v-if='addressUpdateData' isDeparture isAddress></WorkingHoursUpdateInfo>
                    </div>
                </div>
                <div v-if='workerUpdateData' class='b-consult-card b-consult-mt'>
                    <div class='h-pl-30 h-pv-20 h-pr-20'>
                        <div class='h-font-20 h-fw-700 h-mt-10'>
                            {{ $t('CONSULT.INFO.WORKING.HOURS') }}
                        </div>
                        <WorkingHoursUpdateInfo></WorkingHoursUpdateInfo>
                    </div>
                </div>
            </div>
            <div class='b-consult-card b-consult-card--transparent'>
                <div v-for='panel in workerPanel'
                     v-if='!panel.hide'
                     :key='panel.text'
                     :style='{ cursor: panel.isPointer ? "pointer" : "auto" }'
                     class='b-worker-panel'
                     :class='{ "b-worker-panel--disabled": panel.disabled || !canEdit }'
                     @click='canEdit ? swapComponent(panel.popup, panel.disabled) : null'>
                    <div class='b-worker-panel__text'>
                        {{ $t(panel.text) }}
                    </div>
                    <div v-if='panel.arrow'
                         class='b-consult-card__worker-arrow'>
                        <FwIcon
                            class='h-mh-5'
                            icon='arrow-right'
                            size='14'
                            color='#27dbbd'>
                        </FwIcon>
                    </div>
                </div>
                <div class='b-consult-card b-consult-card--list'>
                    <div class='b-consult-card--list-title'>
                        {{ $t('HEADER.SETTINGS') }}
                    </div>
                    <div v-for='panel in workerSubPanel'
                         v-if='!panel.hide'
                         :key='panel.text'
                         class='b-worker-panel--sub'
                         :class='{ "b-worker-panel--sub--disabled": panel.disabled || !canEdit }'
                         :style='{ cursor: panel.isPointer ? "pointer" : "auto" }'
                         @click='canEdit ? swapComponent(panel.popup, panel.disabled) : null'>
                        <div class='b-worker-panel--sub__text'>
                            <span v-if='panel.popup === `SetAvailabilitiesPopup`'>
                                {{ $t(panel.text) }}
                                <span class='h-fw-300'>
                                    {{ availabilitiesText }}
                                </span>
                            </span>
                            <span v-else>
                                {{ $t(panel.text) }}
                            </span>
                            <span v-if='panel.email' class='h-fw-700'>
                                :
                            </span>
                            <template v-if='workerData && workerData.technician_profile'>
                                <span v-if='panel.email && workerData.technician_profile.invitation_status === `not_invited`'
                                      class='h-fw-300'>
                                    {{ $t('WORKER.POPUP.INVITATION.SENT') }}
                                </span>
                                <span v-if='panel.email && workerData.technician_profile.invitation_status === `confirmed` && !workerData.active'
                                      class='h-fw-300'>
                                    {{ $t('WORKER.POPUP.INVITATION.ACCEPTED') }}
                                </span>
                                <span v-if='panel.email && workerData.technician_profile.invitation_status === `confirmed` && workerData.active'
                                      class='h-fw-300'>
                                    {{ $t('WORKER.POPUP.INVITATION.CONFIRMED') }}
                                </span>
                            </template>
                        </div>
                        <div v-if='panel.email && workerData'
                             class='h-flex-center'>
                            <FwButton
                                v-if='workerData.technician_profile.invitation_status === `not_invited` && false'
                                :style='{
                                    color: `#27dbbd`
                                }'
                                color='transparent'
                                :disabled='isRequestSending || !canEdit'
                                fontSize='12px'
                                @click='canEdit ? sendInvitationToUser(worker) : null'>
                                {{ $t('WORKER.POPUP.INVITATION.SEND') }}
                            </FwButton>
                            <FwButton
                                v-if='workerData.technician_profile.invitation_status === `invited` && false'
                                :style='{
                                    color: `#27dbbd`
                                }'
                                color='transparent'
                                :disabled='isRequestSending || !canEdit'
                                fontSize='16px'
                                @click='canEdit ? sendInvitationToUser(worker) : null'>
                                {{ $t('WORKER.POPUP.INVITATION.RESEND') }}
                            </FwButton>
                            <FwIcon
                                v-if='workerData.technician_profile.invitation_status === `confirmed`'
                                class='h-mh-5 h-mr-15 h-pointer'
                                icon='ok'
                                size='18'
                                color='#213F6B'>
                            </FwIcon>
                        </div>
                        <div v-if='panel.switchValue'>
                            <FwSwitcher
                                :disabled='panel.disabled || !canEdit'
                                :isActive='travelTimeStatus || panel.disabled'
                                @toggleSwitch='toggleSwitch()'>
                            </FwSwitcher>
                        </div>
                        <div v-if='panel.arrow'
                             class='b-consult-card__worker-arrow'>
                            <FwIcon
                                class='h-mh-5'
                                icon='arrow-right'
                                size='14'
                                color='#27dbbd'>
                            </FwIcon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <AddEditAdvisorPopup
        v-if='isAdvisorEditPopup'
        @closePopup='closeAdvisorPopup'>
    </AddEditAdvisorPopup>
    <component
        :is='currentComponent'
        :skills='skills'
        :zoomUserId='zoomUserId'
        @getWorker='getWorker'
        @close='currentComponent = false'>
    </component>
    <AddOrEditAddressByForm
        v-if='isAddressUpdatePopup'
        :objectData='workerData'
        updateAction='ValidateDeparturePoint'
        updateType='TechnicianProfile'
        userType='Advisor'
        isAddress
        isAddressSwitch
        @close='isAddressUpdatePopup = false'>
    </AddOrEditAddressByForm>
    <InformationPopup
        v-if='checkWorkingHours'
        :headerText='$t(`WORKER.POPUP.INVITATION.OOPS`)'
        :subMessage='$t(`WORKER.POPUP.INVITATION.CHECK`)'
        :workingHours='worker.is_working_schedule_confirmed'
        :addressConfirm='worker.is_company_address_confirmed'
        :listToApprove='true'
        :companyId='worker.company_id'
        :buttonText='$t(`FINISH.POPUP.SUCCESS.CLOSE`)'
        @close='checkWorkingHours = false'>
    </InformationPopup>
    <InformationPopup
        v-if='isPreventPopupShow'
        :headerText='$t(`UPDATE.ADDRESS.EDIT.TITLE`)'
        :bodyText='$t(`UPDATE.ADDRESS.ALREADY.UPDATED`)'
        :buttonText='$t(`FINISH.POPUP.SUCCESS.CLOSE`)'
        @close='isPreventPopupShow = false'>
    </InformationPopup>
    <GeoZoneReassignmentsPopup
        v-if='isGeoZoneReassignmentsPopupShow'
        :updateListKey='updateListKey'
        @addGeoZone='addGeoZone'
        @close='isGeoZoneReassignmentsPopupShow = false'>
    </GeoZoneReassignmentsPopup>
    <GeoZoneReassignmentPopup
        v-if='isGeoZoneReassignmentPopupShow'
        :worker='workerData'
        @createGeo='createGeo'
        @close='isGeoZoneReassignmentPopupShow = false'>
    </GeoZoneReassignmentPopup>
    <CalizySelfcareLinksPopup
        v-if='isSelfcareUrlsPopupShow'
        :worker='workerData'
        :updateListKey='updateListKey'
        @close='isSelfcareUrlsPopupShow = false'>
    </CalizySelfcareLinksPopup>
</div>
</template>

<script lang='ts'>
import { namespace } from 'vuex-class';
import { Component, Mixins } from 'vue-property-decorator';

import { informationDataType } from '@/types/Consult';
import {
    CategoryLocationSkillNameType,
    CategorySkillNameType,
} from '@/types/Appointment';
import { workerDataType } from '@/types/User';
import { WorkerScheduleTimeType, WorkerType } from '@/types/Workers';

import {
    LOCATION_SKILLS_SEARCH_NAMES,
    BY_PHONE_ID,
    IN_BRANCH_BY_ONLINE_VIDEO,
    IN_BRANCH_PUBLIC_ID,
    IN_BRANCH_VIDEO_ID,
    IN_BRANCH_PRIVATE_VIDEO_ID,
    IN_BRANCH_BY_PHONE_PUBLIC_ID,
    IN_BRANCH_BY_PHONE_PRIVATE_ID,
    IN_BRANCH_BY_VIDEO_PUBLIC_ID,
    IN_BRANCH_BY_VIDEO_PRIVATE_ID,
    ON_SITE_ID,
    IN_BRANCH_BY_ON_SITE,
} from '@/helpers/appointment';
import { capitalizeAll } from '@/helpers/string';

import { GoBackButton } from '@/components/simple/GoBackButton';
import { ConsultCard } from '@/components/simple/ConsultCard/index';
import { WorkerAvatar } from '@/components/nodes/WorkerAvatar/index';
import { InformationPopup } from '@/components/popups/InformationPopup/index';
import { UnavailabilitiesPopup } from '@/components/popups/UnavailabilitiesPopup/index';
import { SetAvailabilitiesPopup } from '@/components/popups/SetAvailabilitiesPopup/index';
import { AvailabilitiesPopup } from '@/components/popups/AvailabilitiesPopup/index';
import { AddOrEditAddressByForm } from '@/views/consult/update-address/AddOrEditAddressByForm';
import { AddEditAdvisorPopup } from '@/components/popups/AddEditAdvisorPopup/index';
import { CalendarSyncPopup } from '@/components/popups/CalendarSyncPopup/index';
import { ReservedSlotsPopup } from '@/components/popups/ReservedSlotsPopup/index';
import { WorkingHoursPopup } from '@/components/popups/WorkingHoursPopup/index';
import { GeoZoneReassignmentsPopup } from '@/components/popups/GeoZoneReassignmentsPopup';
import { GeoZoneReassignmentPopup } from '@/components/popups/GeoZoneReassignmentPopup';
import { CalizySelfcareLinksPopup } from '@/components/popups/CalizySelfcareLinksPopup';
import { WorkingHoursUpdateInfo } from '@/components/simple/WorkingHoursUpdateInfo/index';

import { WorkersWebApi } from '@/api/workers/WorkersApi';
import { AdvisorsWebApi } from '@/api/workers/AdvisorsApi';
import { AccountApi } from '@/api/account/AccountApi';

import AccountMixin from '@/mixins/account';
import PermissionsMixin from '@/mixins/permissions';
import TranslateMixin from '@/mixins/TranslateMixin';

const WorkerPageStore = namespace('WorkerPageStore');
const CommonStore = namespace('CommonStore');

type workerPanelType = {
    href?: string,
    text: string,
    switchValue?: boolean,
    arrow?: boolean,
    state?: boolean,
    popup?: string,
    email?: string,
    hide?: boolean,
    disabled?: boolean
    isPointer?: boolean
}

@Component({
    components: {
        GoBackButton,
        WorkerAvatar,
        ConsultCard,
        InformationPopup,
        AddEditAdvisorPopup,
        CalendarSyncPopup,
        ReservedSlotsPopup,
        WorkingHoursPopup,
        SetAvailabilitiesPopup,
        AvailabilitiesPopup,
        UnavailabilitiesPopup,
        WorkingHoursUpdateInfo,
        AddOrEditAddressByForm,
        GeoZoneReassignmentsPopup,
        GeoZoneReassignmentPopup,
        CalizySelfcareLinksPopup,
    },
})
export default class ConsultAdvisorPage extends Mixins(TranslateMixin, PermissionsMixin, AccountMixin) {
    @WorkerPageStore.Action('storeWorkerData') storeWorkerData!: Function;
    @WorkerPageStore.Mutation('WORKER_DATA') setWorkerData!: Function;
    @WorkerPageStore.Mutation('setScheduleId') setScheduleId!: Function;
    @WorkerPageStore.Mutation('WORKER_UPDATE_DATA') setWorkerUpdateData!: Function;
    @WorkerPageStore.Mutation('ADDRESS_UPDATE_DATA') setAddressUpdateData!: Function;
    @WorkerPageStore.State('workerData') workerData!: workerDataType;
    @WorkerPageStore.State('isUpdateAddressShow') isUpdateAddressShow!: Boolean;
    @WorkerPageStore.State('workerUpdateData') workerUpdateData!: WorkerScheduleTimeType;
    @WorkerPageStore.State('addressUpdateData') addressUpdateData!: any;

    updateListKey: number = 1;
    currentComponent: string = '';
    loading: boolean = true;
    travelTimeStatus: boolean = false;
    isAdvisorEditPopup: boolean = false;
    isPreventPopupShow: boolean = false;
    isAddressUpdatePopup: boolean = false;
    checkWorkingHours: boolean = false;
    isRequestSending: boolean = false;
    isSelfcareUrlsPopupShow: boolean = false;
    isGeoZoneReassignmentPopupShow: boolean = false;
    isGeoZoneReassignmentsPopupShow: boolean = false;
    isGeoZoneFailedReassignmentsPopupShow: boolean = false;
    isCalizySelfcareLinksPopupShow: boolean = false;
    workerPanel: Array<workerPanelType> = [];

    createGeo() {
        this.updateListKey += 1;
    }

    goToAdvisorAddress() {
        if (this.addressUpdateData) {
            this.isPreventPopupShow = true;
        } else {
            this.isAddressUpdatePopup = true;
        }
    }

    get workerScheduleStoreData(): any {
        return this.$store.state.WorkerPageStore.workerScheduleData;
    }

    get workerStatus() {
        return 'technician_profile' || 'manager_profile';
    }

    get skills() {
        return this.workerData && this.workerData.skills ?
            this.workerData.skills.filter((item: CategorySkillNameType) => LOCATION_SKILLS_SEARCH_NAMES.includes(item.searchable_name)) :
            [];
    }

    get showGeoZoneReassignments(): boolean {
        return this.currentUserRole && this.currentUserRole.advisor_rights && this.currentUserRole.advisor_rights.list;
    }

    get isOnSite() {
        return this.workerData.skills.some(e => e.searchable_name === ON_SITE_ID);
    }

    get isOnSiteInBranch() {
        return this.workerData.skills.some(e => e.searchable_name === IN_BRANCH_BY_ON_SITE);
    }

    get isActionsPanelShown() {
        // @ts-ignore-next-line
        return this.workerData && this.workerData.technician_profile && this.workerData.technician_profile.is_taking_appointments && this.isCalizyAccount;
    }

    get zoomUserId() {
        return this.workerData ? this.workerData.zoom_user_id : null;
    }

    get availabilitiesText(): str {
        return this.isAvailabilityFlexType ? this.$t('ADD.RESERVED.SLOT.FORM.SET.UNAVAILABILITY.DEFAULT.TITLE') : this.$t('ADD.RESERVED.SLOT.FORM.SET.AVAILABILITY.DEFAULT.TITLE');
    }

    get isAvailabilityFlexType() {
        // @ts-ignore-next-line
        return this.workerData && (!this.workerData.technician_profile.availability_type || this.workerData.technician_profile.availability_type === 'flex');
    }

    get workerSubPanel() {
        const disabledDistance: boolean = this.skills.length ?
            this.skills.some(item => [
                BY_PHONE_ID,
                IN_BRANCH_BY_ONLINE_VIDEO,
                IN_BRANCH_PUBLIC_ID,
                IN_BRANCH_VIDEO_ID,
                IN_BRANCH_PRIVATE_VIDEO_ID,
                IN_BRANCH_BY_PHONE_PUBLIC_ID,
                IN_BRANCH_BY_PHONE_PRIVATE_ID,
                IN_BRANCH_BY_VIDEO_PUBLIC_ID,
                IN_BRANCH_BY_VIDEO_PRIVATE_ID,
            ].includes(item.searchable_name)) :
            true;

        return [
            {
                text: 'LABEL.STATUS',
                email: 'john.doe@gmail.com',
                isPointer: false,
            },
            {
                text: 'Type :',
                arrow: true,
                popup: 'SetAvailabilitiesPopup',
                isPointer: true,
            },
            {
                text: 'CONSULT.INFO.WORKING.HOURS',
                arrow: true,
                popup: 'WorkingHoursPopup',
                isPointer: true,
            },
            {
                text: 'GLOBAL.SYNCHRONISATION',
                arrow: true,
                popup: 'CalendarSyncPopup',
                isPointer: true,
            },
            {
                text: 'CONSULT.INFO.ADD.DISTANCE.TEXT',
                switchValue: true,
                hide: false,
                state: this.travelTimeStatus,
                disabled: disabledDistance,
                isPointer: true,
            },
        ];
    }

    get isUrlBuilderAvailable(): boolean {
        if (!this.accountData) {
            return false;
        }

        return !!(this.accountData.account.url_builder_support && this.workerData && this.workerData.account_sc_supported);
    }

    closeAdvisorPopup(): void {
        this.isAdvisorEditPopup = false;
        if (this.isUpdateAddressShow) {
            this.isAddressUpdatePopup = true;
        }
    }

    addGeoZone(): void {
        this.isGeoZoneReassignmentPopupShow = true;
    }

    toggleSwitch(): void {
        this.travelTimeStatus = !this.travelTimeStatus;
        this.changeWorkerTravelStatus();
    }

    swapComponent(component: string, disabled?: boolean): void {
        if (disabled) return;
        this.currentComponent = component;
    }

    prepareData(workerData: any): Array<informationDataType> {
        const workerDataArray: Array<informationDataType> = [];
        if (workerData) {
            if (workerData.first_name) {
                workerDataArray.push({
                    title: this.$i18n.t('LABEL.FIRST.NAME'),
                    aside: capitalizeAll(workerData.first_name),
                    header: this.$i18n.t('SELECT.ADDRESS.BRANCH.CONTACT.DETAILS'),
                });
            }
            if (workerData.last_name) {
                workerDataArray.push({
                    title: this.$i18n.t('LABEL.LAST.NAME'),
                    aside: capitalizeAll(workerData.last_name),
                });
            }
            if (workerData.technician_profile && workerData.technician_profile.remote_id) {
                workerDataArray.push({
                    title: this.$i18n.t('LABEL.EXTERNAL.ID'),
                    aside: workerData.technician_profile.remote_id,
                });
            }
            if (workerData.skills && workerData.skills.length) {
                const locationSkills = workerData.skills.filter(
                    (item: CategoryLocationSkillNameType | CategorySkillNameType) => LOCATION_SKILLS_SEARCH_NAMES.includes(item.searchable_name)
                );
                const skills = workerData.skills.filter(
                    (item: CategoryLocationSkillNameType | CategorySkillNameType) => !LOCATION_SKILLS_SEARCH_NAMES.includes(item.searchable_name)
                );
                if (skills && skills.length) {
                    workerDataArray.push({
                        title: this.$i18n.t('LABEL.SKILLS'),
                        // @ts-ignore-next-line
                        aside: `(${skills.length}) ${skills.map((worker: WorkerType) => worker[this.labelKey]).join(', ')}`,
                    });
                }
                if (locationSkills && locationSkills.length) {
                    workerDataArray.push({
                        title: this.$i18n.t('LABEL.APPOINTMENT.METHODS'),
                        // @ts-ignore-next-line
                        aside: `(${locationSkills.length}) ${locationSkills.map((worker: WorkerType) => worker[this.labelKey]).join(', ')}`,
                    });
                }
            }
            if ((workerData[this.workerStatus] && workerData[this.workerStatus].phone) || workerData.phone) {
                workerDataArray.push({
                    title: this.$i18n.t('LABEL.PHONE'),
                    aside: workerData.phone || workerData[this.workerStatus].phone,
                });
            }
            if (workerData.email) {
                workerDataArray.push({
                    title: this.$i18n.t('LABEL.EMAIL'),
                    aside: workerData.email,
                });
            }
            if (workerData.geo_sites) {
                workerDataArray.push({
                    title: this.$i18n.t('LABEL.GEOGRAFIC.ZONE'),
                    aside: `(${workerData.geo_sites.length}) ${workerData.geo_sites.map((worker: WorkerType) => worker.name).join(', ')}`,
                });
            }
            if (workerData.branch) {
                workerDataArray.push({
                    title: this.$i18n.t('WORKER.FORM.BRANCH.LABEL'),
                    aside: capitalizeAll(workerData.branch.name),
                    header: 'WORKER.FORM.BRANCH.MEMBERSHIP',
                });
            }
            if (workerData.branch && workerData[this.workerStatus]) {
                workerDataArray.push({
                    title: this.$i18n.t('LABEL.ADMIN'),
                    aside: workerData[this.workerStatus].branch_admin ? 'LABEL.YES' : 'LABEL.NO',
                });
            }
            if (workerData.hub) {
                workerDataArray.push({
                    title: this.$i18n.t('WORKER.FORM.HUB.LABEL'),
                    aside: capitalizeAll(workerData.hub.name),
                    header: 'WORKER.FORM.HUB.MEMBERSHIP',
                });
            }
            if (workerData.hub && workerData[this.workerStatus] && workerData[this.workerStatus].hub_admin) {
                workerDataArray.push({
                    title: this.$i18n.t('LABEL.ADMIN'),
                    aside: workerData[this.workerStatus].hub_admin ? 'LABEL.YES' : 'LABEL.NO',
                });
            }
        }
        return workerDataArray;
    }

    changeWorkerTravelStatus(): void {
        if (this.workerData) {
            WorkersWebApi.userToggleTravelTime(
                {
                    user_id: this.workerData.id as string,
                    toggle_travel_time: this.travelTimeStatus,
                },
                this.workerData.id as string,
            ).then(response => {
                if (response.status === 200) {
                    console.log('ok');
                }
            }).catch(({ response }) => {
                if (response.status === 401) {
                    console.log('some error');
                } else {
                    this.serverErrors = Object.assign(this.serverErrors, this.prepareServerFormErrors(response.data));
                }
            });
        }
    }

    sendInvitationToUser(worker: WorkerType) {
        this.isRequestSending = true;
        if (worker && (worker.is_working_schedule_confirmed || this.workerScheduleStoreData.confirmed_at) &&
            worker.is_company_address_confirmed) {
            AccountApi.sendInvitationUser({
                user_id: worker.id,
            },
            ).then(response => {
                if (response.status === 200) {
                    this.isRequestSending = false;
                    this.getWorker();
                    this.sentNotif(`WORKER.POPUP.INVITATION.SENT.MESSAGE`);
                }
            }).catch(({ response }) => {
                this.isRequestSending = false;
                this.serverErrors = Object.assign(this.serverErrors, this.prepareServerFormErrors(response.data));
            });
        } else {
            this.isRequestSending = false;
            this.checkWorkingHours = true;
        }
    }

    async getWorker() {
        try {
            const existAdvisor = { data: { advisor: this.workerData }};
            const { data } = this.workerData ? existAdvisor : await AdvisorsWebApi.getAdvisorData({ id: this.$router.currentRoute.params.id });
            await this.storeWorkerData(data.advisor);
            this.setScheduleId(data.advisor.working_schedule_id);
            this.travelTimeStatus = data.advisor.technician_profile.add_travel_time;
            this.setWorkerUpdateData(data.advisor.working_hours_update);
            this.setAddressUpdateData(data.advisor.departure_point_update);

            this.workerPanel = [
                {
                    text: 'CONSULT.INFO.AVAILABILITIES',
                    arrow: true,
                    hide: !this.isAvailabilityFlexType,
                    popup: 'AvailabilitiesPopup',
                    isPointer: true,
                },
                {
                    text: 'CONSULT.INFO.UNAVAILABILITIES',
                    arrow: true,
                    popup: 'UnavailabilitiesPopup',
                    isPointer: true,
                },
                {
                    text: 'CONSULT.INFO.RESERVED.SLOTS',
                    arrow: true,
                    popup: 'ReservedSlotsPopup',
                    disabled: this.skills.length <= 1,
                    isPointer: true,
                },
            ];
            this.loading = false;
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.loading = false;
        }
    }

    created(): void {
        this.setWorkerData(null);
        this.getWorker();
    }
}
</script>

<style lang='sass'>
.b-consult-cards__wrapper-main
    width: 400px

    @include media('<=phone')
        width: 100%

.b-worker-page-main.b-page-container--big
    max-width: 830px

.b-worker-panel--sub
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center
    margin-top: 15px

    &--disabled
        color: #cecece

        path
            fill: #cecece !important

    &__text
        font-size: 16px
        line-height: 22px
        flex: 0 1 75%
        font-weight: 500

    &__admin
        background: #edeef2
        padding: 15px 20px
        border-radius: 5px

    &:not(.b-worker-panel--sub--disabled):hover
        cursor: pointer

        .b-consult-card__worker-arrow
            transform: translate(5px, 0)

    .fw-switcher--disabled
        cursor: not-allowed

        .fw-switcher__slider
            background-color: #eee !important

        .fw-switcher__circle
            background-color: #eee !important
</style>
