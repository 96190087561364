import { CompaniesAddressDataType } from '@/types/Companies';

function getAddressAsString(data: CompaniesAddressDataType): string {
    let { address } = data;
    const { zip_code, zip_code_postfix, country } = data;

    if (address.includes(zip_code) && country) {
        address = address.replace(zip_code, '').trim();
    }

    if (address.includes(country)) {
        address = address.replace(country, '').trim();
    }

    const formattedZipCode = zip_code + (zip_code_postfix || '');

    address = address.split(',').map(item => item.split(/\s+/).join(' ').trim()).filter(Boolean).join(', ');
    if (formattedZipCode && country) {
        address = `${address}, ${formattedZipCode}`;
    }

    if (country) {
        address = `${address}, ${country}`;
    }

    return address.trim();
}

function parseAddress(string: string): CompaniesAddressDataType {
    const regex = /(\b\d{4,5}\b)/;
    const zipCodeData = string.match(regex);

    if (!zipCodeData) {
        throw new Error('No zip code found in the provided string.');
    }

    const zip_code = zipCodeData[0];

    if (string.includes(',')) {
        // If the string has commas, split it by commas.
        const data = string.split(',').map(part => part.trim());
        const address = data[0];
        const country = data[2];

        let zip_code_postfix;
        if (data[1] !== zip_code) {
            zip_code_postfix = data[1].replace(zip_code, '').trim();
        }

        return {
            address,
            zip_code,
            zip_code_postfix,
            country,
        };
    }
    const data = string.split(zip_code).map(part => part.trim());
    const address = data[0];
    const country = data[1];

    return {
        address,
        zip_code,
        country,
    };
}

function addPostCodeToAddress(calizyAddress: string, post_code: string, country: string): string {
    return getAddressAsString({
        address: calizyAddress,
        country,
        zip_code: post_code,
    });
}

export {
    getAddressAsString,
    parseAddress,
    addPostCodeToAddress,
};
